import React, { useState } from 'react';
import { Modal, Form, Button, Spinner } from 'react-bootstrap';
import '../Home.css';
import '../css/ContactFormModal.css';

const ContactFormModal = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNumber: '',
    message: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Show loader

    const requestData = {
      user_name: formData.name,
      user_mail: formData.email,
      msg_body: formData.message,
      user_contactnumber: formData.contactNumber,
    };

    try {
      const response = await fetch('https://quadchain-backend-production.up.railway.app/send_mail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
      // const response = {
      //   status: 200, // Simulate a successful response
      //   text: async () => "Mail sent!", // Simulated response message
      // };
  
      if (response.status === 200) {
        const message = await response.text();
        if (message === "Mail sent!") {
          setIsSuccess(true); 
         
        }
      } else {
        const message = await response.text();
        console.error('Failed to send email:', message);
        alert('Failed to send email. Please try again.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('An error occurred while sending the email.');
    } finally {
      setIsLoading(false); // Hide loader
    }
  };
  const handleCloseModal = () => {
    setIsSuccess(false); // Reset success state
    handleClose(); // Call the parent-provided close handler
  };
  
  return (
    <Modal show={show} onHide={handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Book a Demo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className="text-center success-msg">
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p>Sending email...</p>
          </div>
        ) : isSuccess ? (
          <div className="text-center success-msg">
            <div className="success-icon" style={{ fontSize: '2rem', color: 'green' }}>
              ✔️
            </div>
            <p>Email sent successfully!</p>
          </div>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formContactNumber">
              <Form.Label>Contact Number</Form.Label>
              <Form.Control
                type="tel"
                name="contactNumber"
                placeholder="Enter your contact number"
                value={formData.contactNumber}
                onChange={handleChange}                
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control
                className="textarea-custom"
                as="textarea"
                rows={3}
                name="message"
                placeholder="Enter your message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Button
              className="button-center-banner center-button-banner btn btn-primary"
              type="submit"
            >
              Submit
            </Button>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ContactFormModal;
